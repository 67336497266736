import { render, staticRenderFns } from "./Chef_Supplier_Catalogue.vue?vue&type=template&id=0fafe16c&"
import script from "./Chef_Supplier_Catalogue.vue?vue&type=script&lang=js&"
export * from "./Chef_Supplier_Catalogue.vue?vue&type=script&lang=js&"
import style0 from "./Chef_Supplier_Catalogue.vue?vue&type=style&index=0&id=0fafe16c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAvatar,VBottomNavigation,VBtn,VCard,VCardText,VCol,VContainer,VDataTable,VDialog,VIcon,VImg,VNavigationDrawer,VProgressLinear,VRow,VSelect,VSpacer,VTextField,VToolbar,VToolbarTitle})
